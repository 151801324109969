import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const profile = async () => {

  return {

    displayName: 'Admin',
    email: 'admin@gmail.com',
    photoURL: '/assets/images/avatars/avatar_default.jpg',
  }
}
export const login = async (data) => {
  const loginData = await axios.post(`${API_URL}/admin/login`, data);

  return loginData.data;
}

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    throw new Error("No refresh token found.");
  }

  try {
    // Adjust the method to GET and include the refresh token as a query parameter or header
    const response = await axios.get(`${API_URL}/admin/refresh-token`, {
      params: { refreshToken } // Send refreshToken as a query parameter
    });

    if (response.data.success) {
      localStorage.setItem('token', response.data.data.jwt);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);
      console.log('Tokens refreshed successfully:', {
        token: response.data.data.jwt,
        refreshToken: response.data.data.refreshToken,
      });
    } else {
      throw new Error(response.data.error || 'Failed to refresh token');
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error; // Re-throw the error to be handled in the calling function
  }
};


export const changePassword = async (data) => {
  const response = await axios.post(`${API_URL}/admin/change-password`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  })
  const result = response.data;
  return result;

}