


//    -----------------local url-------------------

export const API_URL = 'https://api.dieselduniya.com/fuel-engine';
export const IMAGE_URL = 'https://api.dieselduniya.com';


// // ----------------------- server url----------------
// export const API_URL = 'http://dieselduniya.com:3001/fuel-engine';
// export const IMAGE_URL = 'http://dieselduniya.com/backend/public';




// // ----------------------- staging server url----------------
// export const API_URL = 'http://staging.dieselduniya.com:3000/fuel-engine';
// export const IMAGE_URL = 'http://staging.dieselduniya.com/backend/public';