import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { login } from '../../../api/auth';

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshTimeout, setRefreshTimeout] = useState(null);

  const handleClick = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      const formElement = document.querySelector('#loginForm');
      const formData = new FormData(formElement);
      const formDataJSON = Object.fromEntries(formData);

      const loginData = await login(formDataJSON);

      // Store JWT token and refresh token immediately
      const jwtToken = loginData.data.jwt; // Assuming this is how you access the JWT token
      const refreshToken = loginData.data.refreshToken;
      
      localStorage.setItem('token', jwtToken); // Store JWT token
      localStorage.setItem('refreshToken', refreshToken); // Store refresh token
      
      setLoading(false);
      navigate('/admin/dashboard/app', { replace: true });

      // Start the timer to re-store the refresh token after 9 seconds
      startRefreshTokenTimer(refreshToken);
    } catch (error) {
      setLoading(false);
      alert(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const startRefreshTokenTimer = (refreshToken) => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }

    // Store the refresh token as the new access token after 9 seconds
    const timeout = setTimeout(() => {
      localStorage.setItem('token', refreshToken); // Use refresh token as access token
    }, 9000); // 9 seconds

    setRefreshTimeout(timeout);
  };

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [refreshTimeout]);

  return (
    <>
      <form id='loginForm' onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField name="email" label="Email" />
          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {loading ? (
          <Stack alignItems="center" justifyContent="center" sx={{ my: 2 }}>
            <CircularProgress />
          </Stack>
        ) : (
          <LoadingButton fullWidth size="large" type="submit" sx={{ my: 2 }} variant="contained">
            Login
          </LoadingButton>
        )}
      </form>
    </>
  );
}
